@charset "utf-8";
/* CSS Document */


/*font-family: 'Josefin Sans', sans-serif;*/

/*common*/
body, html
{
    height:100%;    
}

body 
{
    font-family: 'Lato', sans-serif;
    line-height: 20px;
    font-size:14px;
    background-color:#fff;
    overflow-x: hidden;
    color: #666666;
}
*, *:after, *:before 
{
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
}
a:hover, a:visited, a:focus, a:active, img, button 
{
    border: none;
    outline: none !important;
    text-decoration: none;
}
.btn.focus, .btn:focus {box-shadow: none;}
a 
{
    transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
    color: #231f20;
}

a:hover{color: #e31d1a;}

ul
{
    list-style: none
}

:hover 
{
    transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -webkit-transition: all 300ms ease-in-out;
}

body, body.modal-open
{
    transition: none 0s ease 0s !important;
}

button:focus 
{
    outline: 0;
}

#wrapper
{
    position:relative;
    min-height:100%;
    width:100%;
    float:left;
}
#content
{
    width:100%;
    float:left;
}
.center-div 
{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.centerr-div 
{
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}
.ingredient_1 a:hover {
    color: #0056b3;
}

#footer {
    bottom: 0;
    left: 0;
    padding: 0;
    position: absolute;
    width: 100%;
    z-index: 1;
}

/*
font-family: 'Lora', serif;
font-family: 'Poppins', sans-serif;
font-family: 'Lato', sans-serif;
*/


/* header css Start */

.head_top .logo{display: inline-block;padding: 20px 0;}
.top_social{text-align: left;}

.top_social li{display: inline-block;padding-right: 15px;}
.right_shop{float: right;padding-bottom: 15px;padding-left: 10px;}

.top_social li a{font-size: 14px;}
.right_shop img{margin-top: -3px;margin-left: 10px;}

.right_shop a{font-family: 'Poppins', sans-serif;font-size: 14px;color: #e31d1a;font-weight: 600;}
.navbar-brand{display: none;}

.navbar{width: 100%;    padding: 10px 0;}
.head_top{text-align: center;border-bottom: 1px solid #e6e6e6;}

.custom_search {position: relative;}
input#search-bar{margin: 0 auto;width: 100%;height: 50px;padding: 0 20px;font-size: 1rem;border: 1px solid #e6e6e6;outline: none;border-radius: 25px;}

input#search-bar:focus 
{
    border: 1px solid #fde8e8;
    transition: 0.35s ease;
    color: #999999;
    box-shadow: 0 0 10px #eee;
}


.search-icon{width: auto;}
.search_custom_div{width: 65%;padding-right: 30px;}

.custom_search a 
{
    right: 0px;
    position: absolute;
    top: 0px;
    padding: 13px 28px;
    background: #ececec;
    height: 50px;
    border-radius: 30px;
}
.navbar-expand-lg .navbar-nav{margin-left: 20px;}
header .dropdown-toggle{position: relative;}

header .dropdown-toggle::after
{
    content: "\f078";
    position: absolute;
    font-weight: 900;
    font-size: 16px;
    border: 0;
    top: 50%;
    font-family: 'Font Awesome\ 5 Free';
    padding-left: 3px;
    transform: translateY(-50%);
}

header .dropdown-toggle{font-family: 'Lato', sans-serif;font-size: 15px;color: #231f20 !important;font-weight: 700;padding-left: 0px !important;border: 1px solid #e6e6e6;
border-radius: 25px;min-width:142px;display: inline-block;    text-align: center;padding: 15px 0;height: 50px;background: #fff;}

header .dropdown-toggle:hover , header .dropdown-toggle:focus{background: #e31d1a;color: #fff !important;box-shadow: 1px 4px 10px -5px #e42320;    border: 1px solid #e42320;}

.like , .add_wishlist{color: #fff;background: #fff; border: 1px solid #eaeaea !important;border-radius: 50px;width: 50px;display: inline-block;height: 50px;text-align: center;margin-left: 15px;}
.like i{text-align: center;line-height: 50px;font-size: 20px;color: #e31d1a;}
.like:hover{border: 1px solid #e31d1a !important;}

/* header css End */

/* Banner css Start */

.banner_home .item:before {background: rgba(0,0,0,0.1);width: 100%;height: 100%;left: 0;right: 0;top: 0;bottom: 0;margin: auto;position: absolute;content: "";}
.banner_home .item{height: 620px;width: 100%;background-size: 100%;background-repeat: no-repeat;background-position: center;}

.banner_home .owl-carousel .owl-item img{height: 100%; object-fit:cover;}
.banner_home .container{height: 100%;}

.banner_home .banner_inner{position: relative;height: 100%;}
.banner_home .owl-nav i{font-size: 18px;line-height: 50px;color: #fff;}

.banner_home button.owl-prev 
{
    position: absolute;
    left: 19%;
    top: 50%;
    transform: translateY(-50%);
}
.banner_home button.owl-next 
{
    position: absolute;
    right: 19%;
    top: 50%;
    transform: translateY(-50%);
}

.banner_home button.owl-prev 
{
    background: rgba(80,61,44,0.8)!important;
    border-radius: 50% !important;
    width: 60px;
    height: 60px;
    display: inline-block !important;
    text-align: center;

}

.banner_home button.owl-next
{
    background: rgba(80,61,44,0.8)!important;
    border-radius: 50% !important;
    width: 60px;
    height: 60px;
    display: inline-block !important;
    text-align: center;
}

.bnr_btn{position: relative;display: inline-block;}
.bnr_btn .btn_cus
{
    background: #e31d1a;
    border-radius: 20px;
    padding: 10px 20px;
    color: #ffffff;
    display: inline-block;
    font-size: 14px;
}
.bnr_btn .btn_cus:hover{color: #fff;}
.bnr_btn .btn_cus:after{content: "";position: absolute;left: -30px;background: #ffffff;width: 30px;height: 1px;top: 18px;}  

.bnr_btn .btn_cus:before{content: "";position: absolute;right: -30px;background: #ffffff;width: 30px;height: 1px;top: 18px;}    
.btn_round{height: 360px;width: 360px;margin: 0 auto;border: 10px solid rgba(255,255,255,0.4);border-radius: 50%;}

.round_inner
{
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
}

.btn_round .like{position: relative;bottom: -44px;background: #fff;}
.banner_text{position: absolute;top: 40%;left: 0;right: 0;text-align: center;transform: translateY(-50%);}

.banner_text h1
{
    font-family: 'Lora', serif;
    font-size: 56px;
    color: #fff;
    font-weight: 600;
	padding-top: 30px;
	width: 60%;
	margin: 0 auto;
}

.time_box_main{position: absolute;bottom: 50px;width: 88%;left: 50%;transform: translateX(-50%);}
.time_box{float: left;width: 100%;background: rgba(0,0,0,0.3);border-radius: 40px;text-align: center;max-width: 200px;height: 80px;display: flex;
    flex-direction: row;align-items: center;margin: 0 15px;border: 2px solid rgba(255,255,255,0.4);}

.time_box .img_left{width: 20%;float: left;margin-left: 15px;}
.time_box .text_field{width: 80%;float: left;text-align: left;padding-left: 10px;}

.time_box h4{font-family: 'Lora', serif;font-size: 14px;color: #fff200; margin: 3px 0 6px 0;}
.time_box span{font-family: 'Lato', sans-serif;font-size: 18px;color: #fff;font-weight: 700;}

.time_box img{width: auto !important;height: auto !important;display: inline-block !important;}

/* Banner css End */

/* Letest Recipes css End */

.title_head{text-align: center;padding: 70px 0 40px 0;}
.title_head h3{font-family: 'Lora', serif;font-size: 36px;color: #231f20;letter-spacing: 0.10px;font-weight: 700;}

.title_head span{color: #e31d1a;}
.recipes_box{font-family: 'Lato', sans-serif;margin-bottom: 70px;}

.recipes_box .title_small {position: relative;}
.recipes_box .title_small:after 
{
    content: "";
    position: absolute;
    background: #999999;
    width: 60px;
    height: 1px;
    left: -75px;
    top: 10px;
}
.recipes_box .title_small:before 
{ 
    content: "";
    position: absolute;
    background: #999999;
    width: 60px;
    height: 1px;
    right: -75px;
    top: 10px;
}
.recipes_img span.lazy-load-image-background.blur.lazy-load-image-loaded {
    height: 320px;
}
.recipes_box .title_small img{width: auto !important;margin: 0 auto;}
.recipes_box .recipes_detail h5{font-family: 'Lato', sans-serif;font-size: 14px;color: #e31d1a;font-weight: 400;padding: 15px 0 0;}
.recipes_box .recipes_detail h4{font-family: 'Lato', sans-serif;font-size: 18px;color: #231f20;font-weight: 700;}

.recipes_img{position: relative; height: 320px;}
.recipes_box .recipes_detail{padding: 10px 40px 0;border: 1px solid #e6e6e6;text-align: center;border-radius: 10px;background: #fff; min-height: 200px;}

.time_bottom img{width: auto !important;}
.time_bottom { position: absolute;
    bottom: 0;
    right: 15px;
    bottom: 10px;
    width: auto;
    left: auto;
	text-align: right;
}
.recipes_box .like{position: absolute;right: 10px;background: #fff;top: 5px;}

.recipes_box .recipes_img img{border-radius: 15px;width: 100% !important;transform: scale(1.07);height: 100%;object-fit: cover;}
.recipes_box .recipes_img:after{content: "";position: absolute;left: 0;right: 0;background: rgba(0,0,0,0.2);}

.recipes_box .recipes_img .recipe_plan:after
{ 
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    background-image: -moz-linear-gradient(90deg, rgba(0, 0, 0, 0.75) 10%, rgba(0,0,0,0) 55%);
    /* background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.75) 10%, rgba(0,0,0,0) 55%); */
    background-image: -ms-linear-gradient(90deg, rgba(0, 0, 0, 0.75) 10%, rgba(0,0,0,0) 55%);
    /* background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.75) 10%, rgba(0,0,0,0) 55%); */
    height: 100%;
    width: 100%;
    border-radius: 10px;
    transform: scale(1.07);
    top: 0;
}

.recipes_detail p{padding-bottom: 30px;padding-top: 10px;}
.recipes_box .recipes_img .time_bottom img{width: auto !important;vertical-align: bottom;display: inline-block;margin-right: 10px;}

.recipes_box .recipes_img .time_bottom span{font-size: 14px;color: #fff;}

.recipes_box .btn_cus
{ 
    left: 50%;
    transform: translateX(-50%);
    bottom: 50px;
    position: absolute;
}

.btn_cus
{    
    background: #e31d1a;
    border-radius: 40px;
    padding: 16px 40px;
    color: #ffffff;
    display: inline-block;
    font-weight: 700;
    font-size: 16px;
}

.btn_cus:hover{color: #fff;box-shadow: inherit;box-shadow: 0px 5px 8.1px 0.9px rgba(227, 29, 26, 0.34);transition: 0.5s;}
.owl-theme .owl-nav {margin-top: 0px;}

#latest_slider .owl-item img , #latest_slider2 .owl-item img {display: inline-block !important;margin-right: 10px;}
#latest_slider .owl-item , #latest_slider2 .owl-item{padding: 0 30px;}

#latest_slider .owl-stage-outer , #latest_slider2 .owl-stage-outer{padding: 20px 0 30px;}
#latest_slider .owl-nav i, #tri_combo .owl-nav i{font-size: 22px;line-height: 50px;color: #e31d1a;}

#latest_slider button.owl-prev , #latest_slider2 button.owl-prev, #tri_combo button.owl-prev{position: absolute;left: -55px;top: 35%;transform: translate(-50%);}
#latest_slider button.owl-next, #latest_slider2 button.owl-next , #tri_combo button.owl-next{position: absolute;right: -120px;top: 35%;transform: translate(-50%);}

#latest_slider .owl-nav button,#latest_slider2 .owl-nav button, #tri_combo .owl-nav button
{
    background: #ffffff!important;
    border-radius: 50% !important;
    width: 60px;
    height: 60px;
    display: inline-block !important;
    text-align: center;
	border: 1px solid #e6e6e6;
	color: #e31d1a;
	
}

#latest_slider .owl-dots , #latest_slider2 .owl-dots 
{
    margin-top: 10px;
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
}

/* .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span{background: #e31d1a !important;} */

/* Letest Recipes css End */



/* Try This Combo css Start */

.try_this_combo_sec{background-color: #fef3f3;background-repeat: no-repeat;padding: 0px 0px 70px 0;background-attachment: fixed;background-position: center;}
.try_this_combo_sec .recipes_box .recipes_img img{transform: inherit;}

.try_this_combo_sec .recipes_box .recipes_img .recipe_plan:after{transform: inherit;}
.try_this_combo_sec .item{background: #ffffff;border-radius: 15px;padding: 25px 25px 20px;}

#tri_combo .owl-stage { padding: 50px 0;}
.try_img{height: 270px;width: 100%;}

.try_img img{height: 100%;width: 100%;object-fit:cover;}
.try_left .recipes_detail img{width: auto !important;    padding: 10px 0;}

.try_left .recipes_detail h4{font-size: 24px;color: #231f20;font-weight: 700;padding: 10px 0;margin: 0;}
.try_left .recipes_detail h5{font-size: 14px;color: #666666;}

.try_left .recipes_detail h5 b{font-size: 14px;color: #231f20;font-weight: 700;}
.try_combo_right{float: left;width: 100%;    margin-bottom: 20px;}

.try_box_img{float: left;width: 20%;height: 70px;margin-right: 20px;position: relative;}
.try_box_img:after
{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    /* background-image: -moz-linear-gradient(90deg, rgba(0, 0, 0, 0.75) 10%, rgba(0,0,0,0) 55%);
    background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.75) 10%, rgba(0,0,0,0) 55%);
    background-image: -ms-linear-gradient(90deg, rgba(0, 0, 0, 0.75) 10%, rgba(0,0,0,0) 55%);
    background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.75) 10%, rgba(0,0,0,0) 55%); */
    height: 100%;
    width: 100%;
    border-radius: 20px;
    top: 0;
}

.try_box_img img{height: 100%;border-radius: 15px;}
.try_box_detail{float:left;width: 75%;padding:10px 0 20px;border-bottom: 1px solid #e6e6e6;position: relative;}

.item .try_combo_right:nth-child(4) .try_box_detail{border-bottom: 1px solid #e6e6e6;}
.try_box_detail h5{font-size: 14px;color: #e31d1a;font-weight: 400;}

.try_box_detail h4{font-size: 18px;color: #231f20;font-weight: 700;}
.right_iteam{padding: 0px 0 20px;display: inline-block;}

.try_box_detail .like{position: absolute;right: 40px;top: 10px; background: #fff;}
.try_left .recipe_plan{position: relative;top: -60px;}

.try_left .recipe_plan img{border-radius: 10px;}
.try_left .recipes_detail{margin-top: -30px;}

#tri_combo .btn_cus{position: absolute;right: 35px;}

/* Try This Combo css End */

/* Most populer Recipes css Start */

.most_popular_recipes{padding-bottom: 50px;}
.most_popular_recipes .col-lg-6{padding: 0 30px;}

.most_popular_recipes .recipes_box .title_small {padding-bottom: 0px;display: inline-block;}
.pagination{float: right;}

.pagination .page-link
{
    border: 1px solid #e6e6e6;
    border-radius: 50%;color: #666666;
    font-size: 14px;height: 40px;
    width: 40px;
    line-height: 38px;
    text-align: center;
    padding: 0;
}

.pagination .page-link:hover, .pagination .page-link.active 
{
    z-index: 2;
    color: #e31d1a;
    text-decoration: none;
    background-color: #fbdddd;
    border-color: #e31d1a !important;
}
.pagination .page-item{padding: 0 5px;}
.pagination .page-item:first-child .page-link{border-top-left-radius:0;border-bottom-left-radius:0;border-radius:30px !important;width: 90px;}

.pagination .page-item:last-child .page-link{border-radius: 30px !important;width: 90px;}

/* Most populer Recipes css End */

/* Subscribe Recipes css Start */

.subscribe_newslater{background-repeat: no-repeat;background-size: cover;background-position: center;background-attachment: fixed;}
.subscribe_newslater .title_head {padding: 60px 0;}

.subscribe_newslater .title_head h3{color: #fff;}
.subscribe_newslater .title_head p{font-size: 18px;font-weight: 400;color: #fff;padding: 30px 0 20px; margin: 0;}

.btn_cus.yellow{background: #fff200;color: #231f20;font-size: 15px;font-weight: 700;}

/* Subscribe Recipes css End */



/* Footer css Start */
footer{float: left;width: 100%;}
.foot_top{text-align: center;background-repeat: no-repeat;background-size: 100%;margin: 0px 100px;padding: 80px 0;}

.foot_top p{color: #231f20;}
.foot_top p{color: #231f20;margin: 0;padding: 20px 0;line-height: 18px;}


.foot_top h4{font-family: 'Lato', sans-serif;font-size: 24px;font-weight: 700;color: #231f20;padding-bottom: 10px;}
.social_foot li{display: inline-block;padding: 0 10px;}

.social_foot li a{height: 50px;width: 50px;line-height: 50px; text-align: center;background: #ffffff;border: 1px solid #e6e6e6;display: inline-block;border-radius: 50%;}
.social_foot li a:hover{background-color: rgb(227, 29, 26);box-shadow: 0px 5px 8.1px 0.9px rgba(227, 29, 26, 0.24);color: #ffffff;}

.foot_bottom{padding: 20px 0;border-top: 1px solid #e6e6e6;width: 100%;}
.menu_foot{display: inline-block;padding: 0 10px 0;margin: 0;}

.menu_foot li{display: inline-block; padding-right: 15px;}
.foot_bottom p{text-align: right;margin: 0;}

/* Footer css End */


/*Added By Arkita 20-09-2019*/

/*news letter popup start*/

.theme_popup .modal-header
{
    border: 0;
}
.theme_popup .modal-content
{
    background-image:url('../images/popup_bg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #fff;
    background-position: center;
    border-radius: 10px;
}
.theme_popup .news_letter_box .logo_container 
{
    text-align: center;
    padding-bottom: 30px;
}
.theme_popup .news_letter_box .logo_container img {width: 160px;}
.news_content 
{
    text-align: center;
    margin-bottom: 30px;
    margin-top: 10px;
}
.news_content span 
{
    color: #e31d1a;
    font-size: 18px;
    display: block;
    padding-bottom: 10px;
}
.news_content h4 
{
    font-size: 36px;
    padding-bottom: 6px;
    color: #000;
    font-family: 'Lora', serif;
    font-weight: 600;
}
.news_content p
{
    font-size: 20px;
    color: #000;
}
.news_content .form-control 
{
    width: 100%;
    margin: 0 auto;
    border-radius: 25px;
    height: 48px;
    padding: 18px 22px;
}
.theme_popup .modal-body 
{
    padding: 16px 36px 16px 36px;
}
.form-control:focus 
{
    border-color: #f8cac9;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(248, 202, 201, 0.23);
}
.subscribe_div
{
    display: inline-block;
    width: 100%;
    margin-top: 30px;
}
input::placholder{color: #999;}
button.btn.theme_btn {
    background: #fff200;
    border-radius: 25px;
    padding: 12px 26px;
    color: #231f20;
}
.button_Section a
{
    display: inline-block;
    padding-right: 15px;
    font-size: 16px;
    color: #999;
}

.button_Section {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.theme_popup .close {
    position: absolute;
    right: 32px;
    background: #fff;
    opacity: 1;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    line-height: 30px;
    padding: 0;
    font-size: 22px;
    top: 32px;
    box-shadow: 0 0 10px #eee;
    color: #e31d1a;
    font-weight: bold;
}
/*news letter popup End*/


/*Single recipe page*/
.bread_cum li {
    display: inline-block;
    padding-right: 10px;
    color: #231f20;
    font-weight: 600;
}
.bread_cum{margin-bottom: 20px;}

.bread_cum li a
{
    color: #e31d1a;
}

.single_recipe_section {
    background-image: url(../images/recipe_bg.png);
    padding: 31px 0 15px 0;
    text-shadow: 0 0 black;
    height: 376px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100% center;
    margin-bottom: 148px !important;
}
.cobmbo_single{
     height: 352px;
     margin-bottom: 60px !important;
}
.single_recipe{margin-bottom: 0;}
.recipe_slider_one .itm_se{position: relative;    height: 400px;overflow: hidden;border-radius: 10px;}

.recipe_slider_one .itm_se img {
    width: 100%;
    border-radius: 10px;
    height: 100%;
    object-fit: cover;
}
span.like_section {
    position: absolute;
    right: 15px;
    top: 15px;
    z-index: 999;
}
span.like_section a {
	background: #fff;
	z-index: 999;
}

.recipe_slider_one li img{display: block;
    width: 100%;}

.recipe_slider_one .owl-nav button.owl-next span, .recipe_slider_one .owl-nav button.owl-prev span{
    font-size: 54px;
    background: transparent !important;
    color: #fff;
}
.recipe_slider_one .owl-nav button.owl-next, .recipe_slider_one .owl-nav button.owl-prev {
    background: transparent !important;
}
.recipe_slider_one .owl-nav {
    position: absolute;
    right: 0;
    width: 100%;
    bottom: auto;
    top: 45%;
    z-index: 1;
    transform: translateY(-45%);
}
.recipe_slider_one .owl-nav button.owl-next{position: absolute;
    right: 8px;}
 .recipe_slider_one .owl-nav button.owl-prev{    position: absolute;
    left: 8px;}
.recipe_slider_one .owl-dots {
	position: absolute;
    bottom: auto;
    left: 8px;
    z-index: 1;
    top: 8px;
}
.recipe_slider_one .owl-dots .owl-dot.active span, .recipe_slider_one  .owl-dots .owl-dot:hover span {
    background: #fff;
    height: 20px;
    transition: 0.5s ease-in-out;
}
.recipe_slider_one .owl-dots .owl-dot span { margin: 5px 0px 5px 7px;}

/* .itm_se:after{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    background-image: -moz-linear-gradient(90deg, rgba(0, 0, 0, 0.75) 10%, rgba(0,0,0,0) 55%);
    background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.75) 10%, rgba(0,0,0,0) 55%);
    background-image: -ms-linear-gradient(90deg, rgba(0, 0, 0, 0.75) 10%, rgba(0,0,0,0) 55%);
    background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.75) 10%, rgba(0,0,0,0) 55%);
    height: 100%;
    width: 100%;
    border-radius: 10px;
    top: 0;
} */
.recipe_slider_one .owl-dots .owl-dot span {
    width: 8px;
    height: 8px;
    background: #a7a6a6;
}

.recipe_slider_one .owl-nav button.owl-prev span{padding-right: 13px;}

.share_btn
{
    border-radius: 50%;
    width: 50px;
    height: 50px;
    line-height: 50px;
    padding: 0;
    display: inline-block;
    text-align: center;
    background: #e31d1a;
    box-shadow: 0px 5px 8.1px 0.9px rgba(227, 29, 26, 0.24);
}
.print_btn
{
    background: #fff;
    border-radius: 20px;
    height: 50px;
    padding: 12px 38px;
    box-shadow: 0px 5px 8.1px 0.9px #eee;
    position: relative;
}
.print_btn img
{
    margin-right: 4px;
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
}
.print_btn span {
    display: inline-block;
    padding-left: 14px;
    font-weight: bold;
}
.right_btn{text-align: right;}

.abt_item .title_small:before {
    content: "";
    position: absolute;
    background: #999999;
    width: 60px;
    height: 1px;
    right: -75px;
    top: 10px;
}
.abt_item  .title_small {
    padding-bottom: 50px;
    display: inline-block;
    position: relative;
}
.single_recipe .owl-carousel{margin: 0;}

.abt_item p
{
    font-size: 16px;
    font-weight: bold;
}
.abt_item p span.red
{
    color: #e31d1a;
    display: inline-block;
    padding-right: 10px;
}
.abt_item h2.name_rec {
    font-size: 36px;
    margin-bottom: 16px;
    color: #000;
    font-family: 'Lora', serif;
    font-weight: bold;
}
.abt_item span.style_dish {
    display: block;
    padding-bottom: 16px;
    color: #e31d1a;
    font-weight: bold;
}
.abt_item{padding-top: 40px;position: relative;}

.share_btn img{position: relative;top: -2px;}
.flex_time {
    display: flex;
    align-items: flex-start;
    padding: 15px 0;
}
.div_1 img{margin-right: 14px;}
.text_t p {
    margin-bottom:6px;
    font-size: 15px;
	color: #666666;
	width: 80px;
}

.text_t span {
   font-size: 20px;
    color: #000;
    font-weight: bold;
}

.abt_time {
    position: absolute;
    bottom: 0;
    width: 100%;
}


/*about section*/
.more_abt_hd {
    font-family: 'Lora', serif;
    font-weight: 600;
    color: #000;
}

.more_abt_recipe p {
    color: #231f20;
    font-size: 16px;
    line-height: 22px;
}

.ingredient_1 .used_in{
    display: inline-block;
    margin-right: 15px;
}
.used_in:hover{cursor: pointer;transition: 0.5s;}
.ingredient_1 {
	color: #231f20;
	display: flex;
	align-items: flex-start;
	line-height: 22px;
	padding-bottom: 20px;
	position: relative;
	word-break: break-all;
}
.ingredients_list .col-lg-6{margin-bottom: 20px;}
.ingredients_list .col-lg-6::after
{
	position: absolute;
	content: "";
	bottom: 0;
	left: 15px;
	width: calc(100% - 0px);
	height: 1px;
	background: #e6e6e6;
}
.ingredients_list .col-lg-6:nth-child(2n)::after
{
	left: 0;
	width: calc(100% - 15px);
}



.ingredients_list .row .col-lg-6:nth-child(2n)
{
	padding-right:15px;
}
.ingredients_list {
    /* padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px; */
}

.ingredients_list:first-child {
    border-top: 1px solid #ccc;
    padding-top: 20px;
}
.ingredients_list:last-child{margin-bottom: 0;}
.ingredients_section .title_head h3{font-size: 32px;}
.nutrients_div {
    height: 100%;
    background: #fef3f3;
    border-radius: 10px;
    padding: 0 20px 19px;
}

.nutrients_div .title_head{padding-top: 22px;}
.nutrients_div .title_head h3{font-size: 26px;}


.calories_div
{
    text-align: center;
    background: #fff;
    padding: 10px 7px;
    border-radius: 10px;
    margin-bottom: 15px;
}

.calories_div p {
    margin-bottom: 2px;
     font-family: 'Lora', serif;
}
.calories_div span {
    font-weight: bold;
    color: #000;
    font-size: 18px;
}
.ingredients_section{margin-bottom: 50px;}


/*Added By Arkita 20-09-2019 END*/


.timeline {
  list-style: none;
  padding: 20px 0 20px;
  position: relative;
}

.timeline:before {
 top: 21px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 3px;
    background-color: transparent;
    left: 0%;
    margin-left: -1.5px;
    border-left: 2px dashed #f3c8c8;
}

.timeline > li {
  margin-bottom: 20px;
  position: relative;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li:before,
.timeline > li:after {
  content: " ";
  display: table;
}

.timeline > li:after {
  clear: both;
}

.timeline > li > .timeline-panel {
  width: 92%;
  float: left;
}


.timeline > li > .timeline-badge {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 1.4em;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0%;
  margin-left: -25px;
  z-index: 100;
  border-top-right-radius: 50%;
  border-top-left-radius: 50%;
  border-bottom-right-radius: 50%;
  border-bottom-left-radius: 50%;
}

.timeline-badge{cursor: pointer;}

.timeline > li.timeline-inverted > .timeline-panel {
 float: left;
    margin-left: 50px;
    padding-bottom: 30px;
    position: relative;
}

.image_for_pre
{
    width: 300px;
    border-radius: 10px;
    overflow: hidden;
    display: inline-block;
    margin-right: 15px;
    height: 200px;
}
.image_for_pre img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.timeline-badge span {
    position: absolute;
    bottom: -12px;
}
.timeline-badge {
  background-color: #fef3f3 ;
  color: #e31d1a;
}



.timeline-body > p,
.timeline-body > ul {
  margin-bottom: 0;
}

.timeline-body > p + p {
  margin-top: 5px;
}

.timeline-title {
    color: #231f20;
    font-size: 16px;
    line-height: 22px;
}
.timeline-panel:after {
    content: "";
    position: absolute;
    background: #ccc;
    height: 1px;
    width: 100%;
    bottom: 0;
}
.not_done {
    background-color: #fef3f3;
    color: #e31d1a ;
}

.tags_name li {
    border: 1px solid #ccc;
    padding: 10px 16px;
    border-radius: 20px;
    min-width: 100px;
	text-align: center;
	margin-bottom: 10px;
    margin-right: 10px;
    cursor: pointer;
}
ul.tags_name {
    display: flex;
    align-items: center;
	/* justify-content: space-between; */
	flex-wrap: wrap;
    margin: 30px 0;
}
.tags_name li:hover
{
    border-color: #e31d1a ;
    background: #fbdddd;
}


/*menu start*/
.menu_container{  padding: 30px;width: 100%;position: relative;}
.filter_menu{ padding: 30px;width: 100%;}

.menu_container:before , .filter_menu::before{
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #d8cfcf;
    top: -8px;
    right: 24%;
    transform: translateX(-24%);
}
.filter_menu::before
{
    right: 10%;
    transform: translateX(-10%);
}

.nav-item.show .nav-link
{
    box-shadow: 0 0 10px #eee;
}

.menu_box h4 
{
    font-family: 'Lora', serif;
    color: #231f20;
    font-size: 18px;
    font-weight: 600;
    padding-bottom: 6px;
}

.menu_box ul{padding-left: 25px;}
.menu_box ul li a{padding-left: 0;display: inline-block;}

.menu_box ul li::before {
content: "\2022";
  color: #000;
  font-weight: normal;
  display: inline-block; 
  width: 1em;
  margin-left: -1em;
}
.menu_box ul li:hover::before 
{
    color: #e31d1a;
}
.menu_box ul li:hover a
{
    color: #e31d1a;
    transition: 0.5s;
    background: transparent;
}

.menu_box
{    
    margin-right: 8px;
    position: relative;
    text-shadow: 0 0 black;
    padding-right: 48px;
}



.add_div .col-md-3::after
{
	content: "";
    width: 1px;
    height: 100%;
    right: 15px;
    position: absolute;
    background: #e6e6e6;
    top: 0;
}

.menu_img img 
{
    text-align: right;
    margin-left: auto;
    display: block;
    position: absolute;
    right: -20px;
    top: 50%;
    transform: translateY(-50%);
}
.menu_img 
{
    position: relative;
    display: block;
    height: 40px;
}
.menu_img::before 
{
    content: "";
    width: calc(100% - 5px);
    height: 1px;
    left: 0;
    position: absolute;
    background: #e6e6e6;
    top: 50%;
    transform: translateY(-50%);
}
.navbar-expand-lg .navbar-nav .dropdown-menu 
{
    position: absolute;
    border: 1px solid #ececec;
}
.menu_container {
    max-height: 500px;
    width: 100%;
}
/* .last_child.menu_box::after{display: none;} */
input::placeholder{color: #999 !important;}

.select_custom
{
    height: 50px;
    border-radius: 5px; 
    background: url(../images/down_arrow.png);
    background-size: 13px;
    background-position: 98% 19px;
    padding-right: 30px;
    background-repeat: no-repeat;
    position: relative;
    -webkit-appearance: none;
    border-color: #eaeaea;
}

.filter_form label , .filter_tags
{
    color: #231f20;
    font-weight: 600;
     font-family: 'Lora', serif;
		 font-size: 14px;
		 margin-bottom: 2px;
    padding-bottom: 0px;
}
.filter_tags{margin-top:10px;}

.filter_form ul.tags_name{justify-content: start;margin: 10px 0;}

.filter_form ul.tags_name li{margin-right: 8px;margin-bottom: 8px;}
.cancel_btn
{
    display: inline-block;
    padding-right: 15px;
    font-size: 16px;
    color: #999;
    font-weight: bold;
    margin-right: 10px;
}
.menu_div
{
    margin-right: 15px;
}
.filter_menu_con .dropdown-menu{top: 97%;    margin-top: 0;}

.sticky_menu
{
     z-index: 9;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background: #fff;
    padding-top: 10px;
    transition: all .4s ease-in-out;
    box-shadow:0 3px 12px 2px rgba(0, 0, 0, 0.24);
}

.slide-progress 
{
  width: 0;
  max-width: 100%;
  height: 4px;
  background: #e31d1a;
}

.like i.unliked{color: #cccccc;}

/*search page start*/
.page_title_section 
{
    background: #fef3f3;
    padding: 30px 0;
    margin-bottom: 25px;
}

.page_title_section h4
{
    margin: 0;
    font-size: 30px;
    font-weight: bold;
    color: #000;
    font-family: 'Lora', serif;
}
.page_title_section h4 span{color:#e31d1a; }

.recipe_img_conat 
{
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    height: 300px;
    position: relative;
}
.recipe_img_conat span.timing 
{
    position: absolute;
    right: 15px;
    bottom: 18px;
    color: #fff;
    font-size: 16px;
    z-index: 9;
}

.recipe_img_conat img
{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
}
.recipe_img_conat span.like_section{right:15px;}

.recipe_img_conat span.timing img 
{
    width: 17px;
    margin-right: 10px;
}
.recipe_img{
	height: 100%;
	width: 100%;
}

.recipe_img_conat::after
{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    /* background-image: -moz-linear-gradient(90deg, rgba(0, 0, 0, 0.75) 10%, rgba(0,0,0,0) 55%);
    background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.75) 10%, rgba(0,0,0,0) 55%);
    background-image: -ms-linear-gradient(90deg, rgba(0, 0, 0, 0.75) 10%, rgba(0,0,0,0) 55%);
    background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.75) 10%, rgba(0,0,0,0) 55%); */
    height: 100%;
    width: 100%;
    border-radius: 10px;
    top: 0;
}

.searchitem .abt_item 
{     
    border: 1px solid #ccc;
    border-left: 0;
    padding: 50px 30px; 
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;

}
.searchitem .abt_item .title_small{padding-bottom: 0}
.searchitem .abt_item p{margin: 0;}

.searchitem .abt_item h2.name_rec 
{
    font-size: 22px;
    margin-bottom: 16px;
    margin-top: 4px;
}

.searchitem p.about_recipe_te 
{
    font-weight: normal;
	margin-top: 15px;
	text-overflow: ellipsis;
    overflow: hidden;
	white-space: nowrap;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
}
.view_m_btn 
{
    position: absolute;
    right: 30px;
    bottom: -25px;
}

.searchitem 
{
    margin-bottom: 40px;
    margin-top: 25px;
}

.favorite_dec{margin-top: 60px;}
.favorite_dec .recipes_box .title_small{padding-bottom: 5px;}

.combo_re .itm_se{position: relative;overflow: hidden;height: 310px;  width: 478px;}
.combo_re .itm_se img 
{
    width: 100%;
    border-radius: 10px;
    height: 100%;
    object-fit: cover;
    margin: 0 auto;
}
.favorite_dec .title_head {padding: 0px 0 50px 0;}

.favorite_dec .title_head p
{
    font-weight: bold;
    color: #000;
}

.number_of_combo 
{
    margin-bottom: 30px;
    font-family: 'Lora', serif;
    color: #000;
    font-weight: bold;
    font-size: 18px;
}
.pink_bg 
{
    background: #fef3f3;
    margin-top: 30px;
}
.most_popular_recipes .title_head{padding: 70px 0 50px 0;}

.header_m .title_head{padding: 0px 0 50px 0;}
.fa-instagram{font-weight: 600;}


/* logine modal */

.login_model.modal {z-index: 999999;}
.login_model .modal-header, .language_model .modal-header {
    padding: 10px 0;
    border: 0px;
}
.login_model .modal-title {
   font-family: 'Lora', serif;
    font-size: 36px;
    color: #231f20;
    letter-spacing: 0.10px;
    font-weight: 700;
    margin-bottom: 30px;
    padding: 0;
    line-height: 0;
    margin-bottom: 15px;
    padding: 15px 0;
}
.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
}

.login_model .modal-body{text-align: center;padding: 30px;}

.login_model .form-group lable {font-size: 16px;font-weight: 600; color: #666; line-height: 34px;text-align: left;width: 100%;float: left;}

.login_model button.close, .language_model button.close {
    position: absolute;
    right: 20px;
    top: 20px;
    color: #fff;
    opacity: 1;
    font-size: 32px;
}

.recipes_box .recipes_detail p{
width: 100%;
white-space: nowrap;
overflow: hidden !important;
text-overflow: ellipsis;
}
.video_modal iframe{
    width: 100% !important;
    height: 430px;
}
.test_fb_wrapper_responsive img{width: 100%;}    

.share_btn{position: relative;}

.share_social {
    position: absolute;
    top: 55px;
    width: 150px;
    text-align: left;
    background: #fff;
    padding: 0 10px;
    left: 0;
     z-index: 9;
     display: none;
}
.share_btn:hover{cursor: pointer;}
.share_social li {
    left: inherit;
    border-bottom: 1px solid #eee; 
}
/* .disPly{display: block;} */
.share_social li a{
	display: inline-block;
	width:100%;
}

.modal-backdrop.show {
    opacity: 0.3;
    z-index: 1;
}
.modal {
    z-index: 99;
    background: rgba(0,0,0,0.5);
}

.image_for_pre video{height: 100%;}

.adding_div{width: 100%;}

.add_div .adding_div:last-child{display: none;}

.filter_menu_con  .navbar-expand-lg .navbar-nav .dropdown-menu , .filter_menu 
{
	overflow-y: auto;
	max-height: 400px;
} 


.contact_us .title_head{text-align: left;}
.contact_us .title_head h3{color: #e31d1a;}
.contact_us .form-control{height: 45px;}
.contact_us .form-group lable {font-size: 16px;color: #231f20;line-height: 34px;text-align: left;width: 100%;float: left;}
.contact_us .form-group textarea{width: 100%;height: 120px;}
.contact_inner{padding: 0 100px;}

.add_div .adding_div:last-child{display: none;}
.add_div .adding_div:first-child{display: none;}

.adding_div .col-md-3::after{display: none;}


.SocialMediaShareButton:focus{height: 32px;width: 32px;outline: inherit;}
.SocialMediaShareButton svg {vertical-align: top;}

.right_con
{    
	position: relative;
    height:350px;
    overflow-y: auto;
	display: inline-block;
}
#tri_combo .btn_cus{bottom: -50px;}
.slimScrollBar
{
	background: rgba(227, 29, 26, 0.68) !important;
}
.page-item.active .page-link {
    color: #e31d1a;
    background-color: #fbdddd;
    border-color: #e31d1a !important;
}
.right_shop.dropdown > .dropdown-menu {
	top: 100%;
	transition: 0.3s all ease-in-out;
  }
  
  .right_shop.dropdown:hover > .dropdown-menu {
	display: block;
	top: 92%;
	background: #fbdddd;
  }
  .right_shop.dropdown:hover > .dropdown-menu:hover
  {
	  background: #fbdddd;
	  color: #fff;
  }
  .right_shop > .dropdown-menu:hover .dropdown-item , .right_shop > .dropdown-item{background: transparent;color: #e31d1a;}
  .right_shop.dropdown > .dropdown-toggle:active {
	/*Without this, clicking will make it sticky*/
	pointer-events: none;
  }
 .right_shop > button , .right_shop > button:hover{
    padding: 0 !important;
    height: auto !important;
    background: transparent !important;
    border: 0 !important;
    color: #e31d1a !important;
	box-shadow: none !important;
	font-weight: 700;
}

.try_combo_right{width: 100%;}

.right_con {
    width: 100% !important;
}


.tags_name li{position: relative;}
.tags_name li input
{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
        z-index: 9;
    opacity: 0;
}
.add_border
{
    border-color: #e31d1a !important;
    background: #fbdddd;
}
.login_section {
    background-image: url(../images/login_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 70px 0;
}
.white_box {
    background: #fff;
    padding: 50px;
    border-radius: 6px;
    position: relative;
}
.box_header h4 {
    font-family: 'Lora', serif;
    font-weight: bold;
    font-size: 28px;
}
.form_login {
    margin-top: 30px;
}
.white_box:after {
    content: "";
    position: absolute;
    bottom: -15px;
    width: 90%;
    left: 50%;
    transform: translateX(-50%);
    height: 30px;
    background: #ffffff9c;
    border-radius: 10px;
}
.form_login label {
    font-weight: 600;
}
.form_login .form-control {
    height: 50px;
}
/* When the checkbox is checked, add a blue background */
.container_checkbox input:checked ~ .checkmark 
{
  background-color: #e31d1a;
  outline: none;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after 
{
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container_checkbox input:checked ~ .checkmark:after {display: block;}

/* Style the checkmark/indicator */
.container_checkbox .checkmark:after 
{
  left: 7px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  font-weight: normal;
}
/*checkbox custom*/
.container_checkbox 
{
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #999999;
  font-weight: 600;
}

/* Hide the browser's default checkbox */
.container_checkbox input 
{
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark 
{
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 2px;
}
.btn_red {
    background: #e31d1a;
    border-radius: 25px;
    padding: 12px 26px;
    color: #fff !important;
    font-weight: 600;
}
.main_colr {
	color: #e31d1a !important;
	background: transparent;
}
.width120 {
    min-width: 120px;
}
.bottom_login{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 40px;
}
.bottom_login p{margin-bottom: 0;}

.login_form_pop .form-control
{
	height: 50px;
}

.login_form_pop h4
{
	font-family: 'Lora', serif;
    font-weight: bold;
	font-size: 28px;
	text-align: left;
}

.login_form_pop .form_login label {
	font-weight: 600;
	color: #666;
}
.header_sub{color: #666;text-align: left;}
.button_bootm
{
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 30px;
}
.button_bootm p{margin: 0;}
.main_colr{color: #e31d1a !important;}

.test_fb_wrapper_responsive img {
    width: 100%;
    height: 500px;
    object-fit: cover;
}

.cloase_btn 
{
    position: absolute;
    right: 0;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    background: #000 !important;
    border-radius: 50%;
    top: -11px;
    color: #fff;
    opacity: 2;
	right: -8px;
	z-index: 99;
}

.share_social.disPly{left: 0;}
.selected_users{word-break: break-all;}

.menu_box h4{word-break: break-all;}
.menu_box ul li a{
    word-break: break-word;
    white-space: normal;
    line-height: normal;
}
.menu_box li {
    display: flex;
    align-items: flex-start;
}
.body_scroll{overflow: hidden;}
.red , .errorMsg {
    color: red;
}
.itm_se video {
 height: 100%;
 object-fit: fill; 
}
/* 

 */

.errorMsg {text-align: left;}
body.hidden{overflow: hidden;}

.subscribe_div span
{
	color: red;
    font-size: 16px;
    padding-left: 26px;
	padding-top: 6px;
	text-align: left;
}

.loader {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    display: inline-block;
    z-index: 1;
}
.loader img {
    position: absolute;
    left: 50%;
    top: 22%;
    transform: translate(-50% , -22%);
}
video{position: relative;}
.image_for_pre video:after
{
	content: "";
    position: absolute;
    left: 0;
    right: 0;
    background-image: -webkit-linear-gradient(90deg, rgba(0, 0, 0, 0.75) 10%, rgba(0,0,0,0) 55%);
    height: 100%;
    width: 100%;
    border-radius: 10px;
    top: 0;
}
/* .componentPrint{display: none;} */

label{
	text-align: left;
    width: 100%;
}

.cancel_btn, .btn_newslatter{
	background: transparent;
    padding-right: 15px;
    font-size: 16px;
    color: #999;
}


#return-to-top {
    position: fixed;
    bottom: 40px;
    right: 20px;
    background: rgb(0, 0, 0);
    background: rgba(0, 0, 0, 0.7);
    width: 50px;
    height: 50px;
    display: block;
    text-decoration: none;
    -webkit-border-radius: 35px;
    -moz-border-radius: 35px;
    border-radius: 35px;
    display: none;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
	z-index: 99999;
	text-align: center;
	display: none;
}

#return-to-top i {
    color: #fff;
    margin: 0;
    position: relative;
    left: 0;
    top: 2px;
    font-size: 24px;
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

#return-to-top:hover {
    background: rgba(0, 0, 0, 0.9);
}
#return-to-top:hover i {
    color: #fff;
    top: 5px;
}
.slider_like
{
	position: absolute;
	z-index: 8;
	top: 72%;
    left: 50%;
	transform: translate(-50% , -72%);
	margin-left: 0;
}
.slider_link
{
	display: inherit;
    z-index: 0;
}
.ingredient_1 .used_in{color: #e31d1a !important;}
.ingredient_1 .used_in img {
    margin-right: 10px;
}

/*media query start*/

@media screen and (max-width:1199px)
{

    .time_box {max-width: 164px;height: 70px;}
    .time_box h4{font-size: 12px;}
    
    
}

@media(max-width: 1024px)
{
    .text_t span {font-size: 16px;}
    .ingredient_1{align-items: flex-start;}

    .time_box_main {width: 84%;}
    .search_custom_div {width: 58%;}

    .banner_home button.owl-prev{left: 6%;}
    .banner_home button.owl-next{right: 6%;}

    #latest_slider, #latest_slider2{margin-bottom: 40px;}
    .combo_re .itm_se{width: 396px;margin: 0 auto;}

	.cobmbo_single .bread_cum{padding-top: 20px;}
	.share_social{left:-66px;}

	.time_box {max-width: 164px;}
}

@media screen and (max-width:991px){

    .title_head h3 {font-size: 28px;}
    .banner_home .item{background-size: cover;}
        
    .time_box {max-width: 154px;margin: 0 15px 0 0;}
    .time_box_main{width: 100%;}

    .time_box span{font-size: 16px;}
	.cobmbo_single {background-size: inherit;}
	.share_social {
		left: -58px;
		width: 100px;
	}	

    .single_recipe_section{background-size: cover;}
    .single_recipe .owl-carousel{margin-bottom: 30px;}

    .abt_item .title_small{padding-bottom: 15px;}
    .abt_item{padding-top: 20px;}

    .abt_time{position: relative;}
    .recipe_slider_one .itm_se{height: auto;}

    .ingredients_list{padding-bottom: 0;}
    .ingredient_1{margin-bottom: 20px;} 

    .tags_name li{display: inline-block; margin: 0 3px 7px 0;}
    ul.tags_name{display: inline-block;}

    .search_custom_div {width: 42%;padding-right: 0;}
    .filter_menu::before {right: 16%;transform: translateX(-16%);}

    .menu_container:before, .filter_menu::before{right: 40%;transform: translateX(-40%);}
    .menu_box h4{font-size: 14px;}

    .menu_box{padding-right: 0;}
    .menu_box ul li a{font-size: 12px;}

    .sticky_menu {padding: 12px 0 8px 0;}
	/* .filter_menu_con .dropdown-menu {height: 500px;overflow-y: auto;} */
    .cobmbo_single {background-size: inherit;}
	.single_recipe_section{height: auto;margin-bottom: 45px !important;}
	
	.banner_text h1{width: 100%;}
	.time_box {max-width: 155px;height: 70px;}
    .try_box_detail{width: 72%;}

    .menu_box li{display:flex;align-items:flex-start;}
    .menu_box ul li a {font-size: 12px;word-break: break-word;white-space: normal;line-height: normal;}

    .try_box_detail .like{right: 0;top: 0;}
    .try_box_detail h4 , .try_box_detail h5{padding-right: 50px;}

    .try_this_combo_sec .item{    padding: 25px 25px 60px 20px;}
    #tri_combo .btn_cus {bottom: -82px;}

    .recipe_slider_one .itm_se {height: 300px;}
}

@media(max-width: 767px)
{
    .nutrients_div{margin-top: 30px;}
    .nutrients_div{height: auto;}

    .image_for_pre{width: 100%;}
    .timeline > li > .timeline-panel{width: 75%;}

    .timeline > li > .timeline-badge , .timeline:before{left: 7%;}
    .timeline > li.timeline-inverted > .timeline-panel{margin-left: 60px;}

    .search_custom_div {width: 100%;margin-bottom: 15px;}
    .add_wishlist {width: 50px !important;height: 50px !important;line-height: 50px !important;}

    .menu_div .navbar-nav{margin-left: 0;}
    .menu_box::after{display: none;}

    .menu_box{margin-bottom: 15px;}
    .hide_xs{display: none;}

    .timeline{margin-bottom: 30px;}
    .logo img{width: 50px;}

    .menu_div .nav-link {width: 106px;}
    #latest_slider button.owl-prev, #latest_slider2 button.owl-prev, #tri_combo button.owl-prev {position: absolute;left: -10px;}

    .time_box {max-width: 44%;height: 60px;margin-bottom: 10px;}
    .foot_top{margin: 0px 0px;}

    .btn_round {height: 250px;width: 250px;}
    .banner_text h1 {font-size: 38px;}

    .btn_round .like {bottom: -30px;}
    .banner_home button.owl-prev, .banner_home button.owl-next {width: 40px;height: 40px;    top: 45%;}

    .banner_home .owl-nav i {font-size: 14px;line-height: 42px;color: #fff;}
    .title_head {padding: 30px 0;}

    .title_head h3 {font-size: 20px;margin-bottom: 5px;}
    .title_head img{width: 230px;}

    .menu_foot {text-align: center;width: 100%;}
    .foot_bottom p{text-align: center;padding-top: 5px;}

    /*.recipes_img {height: 150px;}*/
    .like {width: 35px;height: 35px;}

    .like i {line-height: 37px;font-size: 14px;}

    .banner_home button.owl-prev{left: 0}
    .banner_home button.owl-next{right: 0;}

    .btn_cus {padding: 16px 32px;font-size: 14px;}
    .searchitem .abt_item {border: 1px solid #ccc;border-left: 1px solid #ccc;border-bottom-right-radius: 10px;border-bottom-left-radius: 10px;border-top: 0;}

    .page_title_section h4{font-size: 17px;}
    .favorite_dec .recipes_img {height: 250px;}

    #latest_slider .owl-item , #latest_slider2 .owl-item {padding: 0 15px;}
	.combo_re .itm_se{width: 100%;}
	.contact_inner{padding: 0 0px;}

	.time_box{width:45%;}
    .filter_form ul.tags_name li{width: 14px;}

    .recipes_img {height: 250px;} /*change*/
    .try_box_detail {width: 100%;}

    .ingredients_list .col-lg-6:after{    width: calc(100% - 30px);}
    .ingredients_list .col-lg-6:nth-child(2n):after {left: 15px;width: calc(100% - 30px);}

    .right_shop img{display: none;}
    .right_shop a{font-size: 12px;}

	.right_shop{padding-left: 5px;}	
	.test_fb_wrapper_responsive img{height: 300px;}

	.abt_item {width: 10%;}
	.right_shop {padding-left: 4px;} /*change*/

	.logo img {width: 76px;} /*change*/
	.add_div .col-md-3:after{display: none;}
	
	.slider_like{ top: 60%;transform: translate(-50% , -60%);}

}
@media screen and (max-width: 661px){   

	.filter_menu_con .dropdown-menu {
		/* height: auto;
		overflow-y: auto; */
		left: -89px;
		top: 50px;
	}
}

@media(max-width: 425px)
{
    header .dropdown-toggle{min-width: 100px;}
    header .dropdown-toggle::after {font-size: 12px;top: 55%;transform: translateY(-55%);}

    .time_box span{font-size: 12px;}
    .banner_home .owl-carousel .owl-item img{width: 22px !important;}

    .time_box h4{margin-bottom: 0;}
    .banner_text {top: 16%;transform: translateY(-16%);}
	.btn_round .like {bottom: -70px;}
	
	.bottom_login{flex-direction: column;}
    .white_box{padding: 30px;}

    .bottom_login button{margin-bottom: 20px;}
	.box_header h4{font-size: 22px;}
	
	.pagination .page-item:nth-child(1) .page-item:nth-child(0) .page-link , .pagination .page-item:last-child .page-link{width: 68px;font-size: 12px;}
    .pagination .page-item {padding: 0 3px;}

    .right_shop a{font-size: 12px;}
    .button_bootm{flex-direction: column;}

	.button_bootm button{margin-bottom: 20px;}
	.top_social li{padding-right: 10px;}
}
.ingreGrouptitle{
	color: #231f20;
    font-weight: 600;
}
#wordsBreak{
	word-break:break-word
}
.share_social{
	max-height: 0;
    transition: max-height 0.35s ease-out;
	overflow: hidden;
	max-height: 0px;
	transition: max-height 0.45s ease-in;
	/* overflow: hidden; */
}

.share_social.disPly{
	display: block;
	background: transparent;
	width: auto;
	max-height: 200px;
    transition: max-height 0.45s ease-in;
    background: #ffe5e5;
    border-radius: 10px;
    box-shadow: 0px 0px 5px 1px #ff9a98;
    padding: 8px;
}


.share_social li {
border-bottom: 1px solid transparent;
margin-bottom: 4px;
width: 32px;
height: 32px;
}

.abt_item{width: 90%;}


/* Loader Css *******Start*************** */

 /* Absolute Center Spinner */
.loading {
	position: fixed;
	z-index: 999;
	height: 100%;
	width: 100%;
	overflow: show;
	margin: auto;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
  }
  
  /* Transparent Overlay */
  .loading:before {
	content: '';
	display: block;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0, .8));
	background: -webkit-radial-gradient(rgba(20, 20, 20,.8), rgba(0, 0, 0,.8));
  }
  
  /* :not(:required) hides these rules from IE9 and below */
  
  
  .loading:not(:required):after {
	content: '';
	display: block;
	font-size: 10px;
	width: 1em;
	height: 1em;
	margin-top: -0.5em;
	-webkit-animation: spinner 150ms infinite linear;
	-moz-animation: spinner 150ms infinite linear;
	-ms-animation: spinner 150ms infinite linear;
	-o-animation: spinner 150ms infinite linear;
	animation: spinner 150ms infinite linear;
	border-radius: 0.5em;
	-webkit-box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
	box-shadow: rgba(255,255,255, 0.75) 1.5em 0 0 0, rgba(255,255,255, 0.75) 1.1em 1.1em 0 0, rgba(255,255,255, 0.75) 0 1.5em 0 0, rgba(255,255,255, 0.75) -1.1em 1.1em 0 0, rgba(255,255,255, 0.75) -1.5em 0 0 0, rgba(255,255,255, 0.75) -1.1em -1.1em 0 0, rgba(255,255,255, 0.75) 0 -1.5em 0 0, rgba(255,255,255, 0.75) 1.1em -1.1em 0 0;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50% , -50%);
	z-index: 9999;
}
  
  /* Animation */
  
  @-webkit-keyframes spinner {
	0% {
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -ms-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	100% {
	  -webkit-transform: rotate(360deg);
	  -moz-transform: rotate(360deg);
	  -ms-transform: rotate(360deg);
	  -o-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
  }
  @-moz-keyframes spinner {
	0% {
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -ms-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	100% {
	  -webkit-transform: rotate(360deg);
	  -moz-transform: rotate(360deg);
	  -ms-transform: rotate(360deg);
	  -o-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
  }
  @-o-keyframes spinner {
	0% {
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -ms-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	100% {
	  -webkit-transform: rotate(360deg);
	  -moz-transform: rotate(360deg);
	  -ms-transform: rotate(360deg);
	  -o-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
  }
  @keyframes spinner {
	0% {
	  -webkit-transform: rotate(0deg);
	  -moz-transform: rotate(0deg);
	  -ms-transform: rotate(0deg);
	  -o-transform: rotate(0deg);
	  transform: rotate(0deg);
	}
	100% {
	  -webkit-transform: rotate(360deg);
	  -moz-transform: rotate(360deg);
	  -ms-transform: rotate(360deg);
	  -o-transform: rotate(360deg);
	  transform: rotate(360deg);
	}
  }
 


  #vidoe_modal{
	opacity: 1 !important;
	z-index: 999;
  }

  .productor_btn {
    display: inline-block !important;
    white-space: normal;
    min-width: 182px !important;
    margin-left: 15px;
}
.productor_btn:after{display: none;}


.nuestros_btn .dropdown-toggle .dropdown-menu{display: none;}
.nuestros_btn .dropdown-toggle:hover .dropdown-menu{display: inline-block;}
.dropdown.nuestros_btn {
    margin-left: 14px;
}
@media(max-width:768px)
{
	.search_custom_div{margin-bottom: 10px;}
}
@media(max-width:767px)
{
.dropdown.nuestros_btn .btn {
    width: 130px;
}
.filter_menu_con .dropdown-menu.menu_container.show, .dropdown-menu.filter_menu.show {
    left: 0;
    top: 120px;
}
.menu_div{margin-bottom: 10px;}
/* .filter_menu_con .dropdown-menu{top: 55px;} */
.dropdown.nuestros_btn{position: relative;}
.filter_menu_con .nuestros_btn .dropdown-menu.show {
    left: auto;
    top: 50px;
    width: 100% !important;
    word-break: break-word;
    right: 0;
}
.filter_menu_con .dropdown-menu.show .dropdown-item{white-space: inherit;}
}
@media(max-width:380px)
{
	.dropdown.nuestros_btn {
		margin-left: 0;
	}
	.filter_menu_con .nuestros_btn .dropdown-menu.show{right: auto;left: 0;}
}
.dropdown-item{cursor: pointer;}


.video_button{
    position: absolute;
    left: 15px;
    bottom: 18px;
    color: #fff;
    font-size: 16px;
    z-index: 9;
    background: #fff;
    width: 42px;
}

.recipes_img .video_button img{
    width: 40px !important;
    margin-left: 1px;
}

.video_button img{
    width: 40px !important;
}

/*
	*
	*	TEXTOS JUSTIFICADOS
	*
*/
.estilos_justificados p{
	text-align: justify;
}