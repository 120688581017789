

@media screen and (max-width:1366px){
	
}




@media screen and (max-width:991px){

.title_head h3 {font-size: 28px;}
	
}


@media screen and (max-width:767px){

	#latest_slider button.owl-prev, #tri_combo button.owl-prev {position: absolute;left: -10px;}
	.time_box {max-width: 44%;height: 60px;margin-bottom: 10px;}
	.foot_top{margin: 0px 0px;}
	.btn_round {height: 250px;width: 250px;}
	.banner_text h1 {font-size: 38px;}
	.btn_round .like {bottom: -30px;}

	.banner_home button.owl-prev, .banner_home button.owl-next {width: 40px;height: 40px;    top: 45%;}

	.banner_home .owl-nav i {font-size: 14px;line-height: 42px;color: #fff;}

	.title_head {padding: 30px 0;}
	.title_head h3 {font-size: 20px;margin-bottom: 5px;}
	.title_head img{width: 230px;}
	.menu_foot {text-align: center;width: 100%;}
	.foot_bottom p{text-align: center;padding-top: 5px;}
	.recipes_img {height: 150px;}
	.like {width: 35px;height: 35px;}

	.like i {line-height: 37px;font-size: 14px;}
  	
}

@media screen and (max-width:639px){

	

}    

@media screen and (max-width:479px){

	
}

@media screen and (max-width:360px){

	
}

